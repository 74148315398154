import { render, staticRenderFns } from "./StudyNewPageCommon.vue?vue&type=template&id=ffaf97d0&scoped=true"
import script from "./StudyNewPageCommon.vue?vue&type=script&lang=js"
export * from "./StudyNewPageCommon.vue?vue&type=script&lang=js"
import style0 from "./StudyNewPageCommon.vue?vue&type=style&index=0&id=ffaf97d0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffaf97d0",
  null
  
)

export default component.exports