<template>
  <div class="StudyNewPageCommon">
    <div class="StudyNewPageCommon__header">
      <StudyNewPageCommonHeader
        :study="study"
        :conversation-streams="!isFetching ? conversationStreams : null"
        :questions="questions"
        :topics="topics"
        :details="!study.isLive"
        @studyUpdate="onStudyUpdate"
      />
    </div>
    <div v-if="isDelimeterVisible" class="StudyNewPageCommon__delimeter"></div>
    <div class="StudyNewPageCommon__content">
      <div v-if="isFetching" class="StudyNewPageCommon__loading">
        <LLLoader></LLLoader>
      </div>
      <div v-else-if="error" class="StudyNewPageCommon__error">
        <LLLoaderError @retry="fetchConversationStreams"></LLLoaderError>
      </div>
      <div v-else-if="study.isLive" class="StudyNewPageCommon__conversation">
        <StudyNewPageBody
          ref="body"
          :conversation-streams="conversationStreams"
          :questions="questions"
          :topics="topics"
          :study="study"
          @updateMessage="onUpdateMessage"
          @fetchTopicsMeta="fetchTopicsMeta"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { StudyModel } from '@/models/study'
import { ConversationStreamModel } from '@/models/conversationStream'
import LLLoader from '@/components/common/LLLoader.vue'
import LLLoaderError from '@/components/common/LLLoaderError.vue'
import StudyNewPageBody from '@/components/pages/study-new/page-body/StudyNewPageBody.vue'
import studyMessages from '@/utils/modules/studyMessages'
import { StudyConversationMessageModel } from '@/models/studyConversationMessage'
import StudyNewPageCommonHeader from '@/components/pages/study-new/common/StudyNewPageCommonHeader.vue'
import { StreamState } from '@/common/enums'

export default {
  name: 'StudyNewPageCommon',
  components: { StudyNewPageCommonHeader, StudyNewPageBody, LLLoaderError, LLLoader },
  props: {
    study: { type: StudyModel, required: true },
    /**
     * @type {TopicModel[]}
     */
    topics: { type: Array, default: () => [] },
    /**
     * @type {QuestionModel[]}
     */
    questions: { type: Array, required: true, default: () => [] }
  },
  data() {
    return {
      conversationStreams: [],
      isFetching: false,
      error: false
    }
  },
  computed: {
    isDelimeterVisible() {
      return this.isFetching || this.error || this.study?.isLive
    }
  },
  mounted() {
    this.fetchConversationStreams()
    studyMessages.addEvent(
      studyMessages.enums.CONVERSATION_STREAM_MESSAGE_UPDATE,
      this.onUpdateMessageFromSocket
    )
    studyMessages.addEvent(
      studyMessages.enums.CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED,
      this.onUpdateMessage
    )
    studyMessages.addEvent(studyMessages.enums.CONVERSATION_STERAM_STATE, this.onUpdateStreamState)
    studyMessages.addEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.onUpdateQuestionVisibility)
    studyMessages.addEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.fetchTopicsMeta)
    studyMessages.addEvent(studyMessages.enums.QUESTION_UPDATE, this.fetchConversationStreamsSilent)
    studyMessages.addEvent(studyMessages.enums.QUESTION_DELETE, this.fetchConversationStreamsSilent)
    studyMessages.addEvent(studyMessages.enums.TOPIC_DELETE, this.fetchConversationStreamsSilent)
    studyMessages.addEvent(studyMessages.enums.TOPIC_UPDATE, this.fetchConversationStreamsSilent)
  },
  beforeDestroy() {
    studyMessages.removeEvent(
      studyMessages.enums.CONVERSATION_STREAM_MESSAGE_UPDATE,
      this.onUpdateMessageFromSocket
    )
    studyMessages.removeEvent(
      studyMessages.enums.CONVERSATION_STREAM_MESSAGE_UPDATE_PARSED,
      this.onUpdateMessage
    )
    studyMessages.removeEvent(studyMessages.enums.CONVERSATION_STERAM_STATE, this.onUpdateStreamState)
    studyMessages.removeEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.onUpdateQuestionVisibility)
    studyMessages.removeEvent(studyMessages.enums.QUESTION_VISIBILITY_UPDATE, this.fetchTopicsMeta)
    studyMessages.removeEvent(studyMessages.enums.QUESTION_UPDATE, this.fetchConversationStreamsSilent)
    studyMessages.removeEvent(studyMessages.enums.QUESTION_DELETE, this.fetchConversationStreamsSilent)
    studyMessages.removeEvent(studyMessages.enums.TOPIC_DELETE, this.fetchConversationStreamsSilent)
    studyMessages.removeEvent(studyMessages.enums.TOPIC_UPDATE, this.fetchConversationStreamsSilent)
  },
  methods: {
    onUpdateQuestionVisibility({ questionsToUpdate }) {
      questionsToUpdate.forEach((questionToUpdate) => {
        const { questionId } = questionToUpdate

        const conversationStream = this.conversationStreams.find(
          (conversationStream) => conversationStream.questionId === questionId
        )

        if (!conversationStream) {
          return
        }

        if (questionToUpdate.isVisible === false) {
          conversationStream.messages = []
          conversationStream.state = StreamState.NOT_ANSWERED
        }
      })
    },
    fetchTopicsMeta() {
      this.$emit('fetchTopicsMeta')
    },
    onUpdateStreamState({ questionId, state }) {
      const conversationStream = this.conversationStreams.find(
        (conversationStream) => conversationStream?.questionId === questionId
      )
      if (conversationStream) {
        conversationStream.state = state
      }
    },
    fetchConversationStreamsSilent() {
      this.fetchConversationStreams({ silent: true })
    },
    async fetchConversationStreams(options) {
      this.error = null
      const { silent } = options || {}
      this.isFetching = !silent
      try {
        const { conversationStreams } = await this.$api.studies.getConversationStream({
          studyId: this.study.id
        })
        this.conversationStreams = conversationStreams
          .map((conversationStream) => {
            /*const questionId = conversationStream.questionId
            const question = this.questions.find((question) => question.id === questionId)
            if (!question) return*/
            return ConversationStreamModel.parseFromApi({
              conversationStream,
              studyId: this.study.id
            })
          })
          .filter((conversationStream) => conversationStream)
      } catch (e) {
        console.log(e)
        this.error = true
      } finally {
        this.isFetching = false
      }
    },
    onUpdateMessageFromSocket({ message, questionId, studyId }) {
      const conversationStream = this.conversationStreams.find((stream) => stream.questionId === questionId)
      const parsedMessage = StudyConversationMessageModel.parseFromApi({
        message,
        questionId,
        studyId,
        pollType: conversationStream?.pollType
      })
      this.onUpdateMessage({ message: parsedMessage })
    },
    onUpdateMessage({ message }) {
      const conversationStream = this.conversationStreams.find(
        (conversationStream) => conversationStream.questionId === message.questionId
      )
      const messages = conversationStream.messages
      const messageIndex = messages.findIndex((oldMessage) => oldMessage.id === message.id)

      messageIndex !== -1 ? this.$set(messages, messageIndex, message) : messages.push(message)
    },
    onStudyUpdate({ study }) {
      this.$emit('studyUpdate', { study })
    }
  }
}
</script>

<style scoped lang="scss">
.StudyNewPageCommon {
  &__header {
    @apply w-full;
  }
  &__loading {
    @apply h-48 items-center justify-center flex;
  }
  &__error {
    @apply h-48 items-center justify-center flex;
  }
  &__content {
  }
  &__delimeter {
    @apply w-full h-px bg-neutral-01-50 my-12;
  }
}
</style>
