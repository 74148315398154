<template>
  <div class="LLWysiwygOutput">
    <div v-if="!usingSlot" class="LLWysiwygOutput__html" v-html="html"></div>
    <slot v-else></slot>
  </div>
</template>

<script>
import sanitizeHtml from 'sanitize-html'
import emojiToHtml from '../../utils/emojiToHtml'
import mentionToHtml from '@/utils/mentionToHtml'

const allowedTags = ['strong', 'em', 'ol', 'br', 'u', 'p', 'ul', 'li', 'a']
export default {
  name: 'LLWysiwygOutput',
  props: {
    text: { type: String, default: '' },
    usingSlot: { type: Boolean, default: false },
    sanitizeFull: { type: Boolean, default: false }
  },
  computed: {
    html() {
      return !this.sanitizeFull ? this.sanitizedHtml : this.sanitizedFull
    },
    sanitizedHtml() {
      return mentionToHtml.span(
        emojiToHtml.span(
          sanitizeHtml(this.text, {
            allowedTags
          })
        )
      )
    },
    sanitizedFull() {
      return mentionToHtml.span(
        emojiToHtml.span(
          sanitizeHtml(this.text, {
            allowedTags: []
          })
        )
      )
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.LLWysiwygOutput {
  @apply cursor-text;
  ::v-deep {
    * {
      @apply break-words;
      font-family: 'Open Sans', 'Arial', 'Noto Color Emoji', 'Noto Emoji', sans-serif;
    }
    .emoji {
      @apply w-4 inline select-all pointer-events-none;
    }
    .mention {
      @apply text-accent-01;
    }
    ul {
      list-style: disc;
      padding-left: 1.5rem;
    }
    ol {
      list-style: decimal;
      padding-left: 1.5rem;
    }
    a {
      @apply text-primary-01-300 underline;
    }
    p {
      min-height: 1em;
    }
  }
}
</style>
