import moment from 'moment'
export const getAge = (birthDate) => {
  const ageDifMs = Date.now() - new Date(birthDate).getTime()
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const getBirthYearsArray = () => {
  const currentYear = moment().year()
  const years = []
  const minimumAge = 13
  for (let i = currentYear - minimumAge; i > currentYear - 100 - minimumAge; i--) {
    years.push(i)
  }
  return years
}
